import 'bootstrap';
import './scss/app.scss';
//import logo_svg from './svg/bufetPuig_min.svg';
//import img1_jpg from './jpg/edificiBufet.jpg';
//import duo_svg from './svg/duo_min.svg';
//import img2_jpg from './jpg/oficinaBufet.jpg';
//import img3_jpg from './jpg/paretBufet.jpg';
/*import './svg/bufetPuig_min.svg';
import './jpg/edificiBufet.jpg';
import './svg/duo_min.svg';
import './jpg/oficinaBufet.jpg';
import './jpg/paretBufet.jpg';
import './webp/edificiBufet.webp';
import './webp/oficinaBufet.webp';
import './webp/paretBufet.webp';*/

window.onload = function () {
  cookies();
  bars();
  window.addEventListener('resize', bars);
  document.addEventListener('scroll', navPosition);
  window.d = document.getElementById('contact').getBoundingClientRect().top;

  document.getElementById('aContact').addEventListener('click', function (e) {
    e.preventDefault();
    //e.stopPropagation();
    if (window.innerWidth >= 991) {
      contact();
    } else {
      document.getElementById('nav-bars').click();
      contact();
    }
  });

  if (window.location.pathname !== "/legal.html") {
    //console.log("not");
    return;
  } else {
    //document.querySelectorAll('.f-link')[0].children[0].href = "./legal.html#";
    if (window.location.hash == "#privacitat") {
      document.querySelectorAll('.nav_legal')[1].click();
      //console.log("1");
    } else if (window.location.hash == "#galetes") {
      document.querySelectorAll('.nav_legal')[2].click();
      //console.log("2");
    } else if (window.location.hash == "#cookies") {
      document.querySelectorAll('.nav_legal')[3].click();
      //console.log('3');
    } else if (window.location.hash == "#") {
      document.querySelectorAll('.nav_legal')[0].click();
    };
  };

};

document.querySelectorAll('.nav_legal').forEach(item => {
  item.addEventListener('click', event => {
    for (var i = 0; i < event.target.parentNode.children.length; i++) {
      if (event.target.parentNode.children[i] == event.target) {
        //console.log(true);
        event.target.parentNode.children[i].classList.add('active');
        document.querySelectorAll('.multi-collapse')[i].classList.add('show');
        document.querySelectorAll('.f-link')[i].childNodes[0].click();
      } else {
        //console.log(false);
        event.target.parentNode.children[i].classList.remove('active');
        document.querySelectorAll('.multi-collapse')[i].classList.remove('show');
      };
    };
  });
});

window.addEventListener('hashchange', function () {
  if (window.location.pathname !== "/legal.html") {
    cookiesGoogle();
    return
  } else {
    if (window.location.hash == "#privacitat") {
      document.querySelectorAll('.nav_legal')[1].click()
    } else if (window.location.hash == "#galetes") {
      document.querySelectorAll('.nav_legal')[2].click()
    } else if (window.location.hash == "#cookies") {
      document.querySelectorAll('.nav_legal')[3].click()
    } else if (window.location.hash == "#") {
      document.querySelectorAll('.nav_legal')[0].click();
    };
  }
});

function cookiesGoogle() {
  document.getElementsByName('cAnalytics').forEach(item => {
    item.addEventListener('input', function () {
      console.log('sss');
    })
  });
};

function contact() {
  window.scrollTo({
    top: window.d + 69,
    behavior: 'smooth',
  });

};

function bars() {
  //window.d = document.getElementById('contact').getBoundingClientRect().top;
  //console.log(window.d);
  var ww = window.innerWidth;
  var b = document.getElementById("nav-bars");
  var m = document.getElementsByClassName('col col-email min-w v-none');
  var i;
  if (ww <= 991) {
    b.classList.remove("d-none");
    try {
      //m[0].classList.add('d-none');
      m[1].classList.add('d-none');
    } catch {};
    document.getElementById('linkedin').classList.add('reduir');
  } else {
    if (ww > 991)
      b.classList.add("d-none");
    try {
      m[0].classList.remove('d-none');
      m[1].classList.remove('d-none');
    } catch {};
    document.getElementById('linkedin').classList.remove('reduir');
  };
};

function navPosition() {
  try {
    var w = window.scrollY;
    var a = document.getElementById('cacotaScrollTop');
    var aRect = a.getBoundingClientRect();
    var position = {
      top: aRect.top + window.pageYOffset
    };
    var nav = document.getElementById('navID');
    var navHeight = nav.clientHeight;
    var NumPX = 0;
    var n = position.top - navHeight - NumPX;
    rmTransparent(w, n);
  } catch {}
};

function rmTransparent(w, n) {
  var MenuParent = document.getElementById('navMenuParent').children;
  if (w >= n) {
    document.getElementById('navID').classList.remove('transp');
    for (const children of MenuParent) {
      children.classList.remove('transp');
    };
    document.getElementById('logoNav').parentNode.classList.remove('v-none');
    document.getElementById('nav-bars-svg-path').style.fill = '#818285';
  } else {
    document.getElementById('nav-bars-svg-path').style.fill = '#ffffff';
    document.getElementById('logoNav').parentNode.classList.add('v-none');
    for (const children of MenuParent) {
      children.classList.add('transp');
    };
    document.getElementById('navID').classList.add('transp');
  };
};

function cookies() {
  //console.log(decodeURIComponent(document.cookie).split(';'));
  if (getCookie("cookie_policy") == "") {
    //console.log("cookie not set");
    document.getElementById('cookieAlert').classList.remove('d-none');
    document.getElementById('cookieAlert').addEventListener('click', function () {
      setCookie("cookie_policy", "ok", 365);
      document.getElementById('cookieAlert').classList.add('d-none');
    });
  } else {
    //console.log("cookie already set");
    document.getElementById('cookieAlert').classList.add('d-none');
  }
};

function getCookie(cname) {
  var name = cname + "=";
  var ca = decodeURIComponent(document.cookie).split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      //console.log("cookie found");
      return true;
    }
  }
  //console.log("cookie not found");
  return "";
};

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";" + "path=/;" + "samesite=lax;";
  //document.cookie = cname + "=" + cvalue + ";" + expires + ";" + "path=/;" + "secure;" + "domain=giroweb.com" + "samesite=lax;";
};